<template>
      <ValidationObserver ref="formkiw">
        <form @submit.prevent="validate" id="usrsetupcc">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                        <div class="or-container">
                            <div class="line-separator"></div>
                            <div class="or-label">Service and Billing Agreement</div>
                            <div class="line-separator"></div>
                        </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <iframe ref="iframeRef" src="/service-billing-agreement" width="100%"  height="400vh" @load="onIframeLoad" style="border-radius: 8px;" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 ">
                    <base-checkbox :disabled="agreeTermDisable" v-model="agreeTermBilling" class="pull-left" :class="{'has-danger': agreeTermStatBilling}">I have read and agree to the <a href="/service-billing-agreement/" target="_blank" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[agreeTermStatBilling?{'color':'#ec250d'}:'']"> service and billing agreement</a></base-checkbox> 
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { mapActions } from "vuex";
import { decryptData } from '../../../helpers/crypto'
import { DOCUMENTS } from '../../../shared/constants'

export default {
    data() {
        return {
            agreeTermDisable: true,
            agreeTermBilling: false,
            agreeTermStatBilling: false,
        }
    },
    methods: {
        ...mapActions(['userLogSaved']),
        onIframeLoad(){
            let iframe = this.$refs.iframeRef;
            try {
                let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

                if(iframeDoc){
                    const style = iframeDoc.createElement('style');
                    style.innerHTML = '.full-page > .content { padding-top: 0 !important; padding-bottom: 40px !important; }';
                    iframeDoc.head.appendChild(style);
                }

                iframeDoc.addEventListener("scroll", () => {
                    let scrollHeight = iframeDoc.documentElement.scrollHeight;
                    let scrollTop = iframeDoc.documentElement.scrollTop;
                    let clientHeight = iframeDoc.documentElement.clientHeight;

                    if (scrollTop + clientHeight >= scrollHeight - 10) {
                        this.agreeTermDisable = false;
                    }
                });
            } catch (error) {
                console.warn("cross-origin restriction.");
            }
        },
        async validate(){
            $('.btnNext2').attr('disabled', true).html('<i class="fa fa-spinner fa-spin"></i> <span style="margin-left: 8px;">Loading...</span>');

            if(this.agreeTermBilling){
                const userData = this.$store.getters.userData;
                let desc = `The user has agreed to the Service and Billing Agreement. | Menu: Use Existing Credit Card | fileUrl: ${DOCUMENTS.SERVICE_BILLING_AGREEMENT}`;

                const payload = {
                    userID: userData.id,
                    action: "Service and Billing Agreement",
                    desc,
                    userIP: this.$store.getters.userData.ip_login,
                }
                const userViewMode = userData.isViewMode ? userData.isViewMode : false;
                if(userViewMode){
                    const userRoot = await decryptData(localStorage.getItem('userDataOri'))
                    payload.userID = userRoot.id;
                }
                
                await this.userLogSaved({ data: payload });
                this.makeFinishSetup();
                
                $('.btnNext2').attr('disabled', false).html('Go To General Settings');
                return true
            } else {
                this.agreeTermStatBilling = true;
                $('.btnNext2').attr('disabled', false).html('Next');
                return false
            }
        },
        makeFinishSetup() {
            var user = this.$store.getters.userData
            this.$store.dispatch('userSetupComplete', {
                usrID: user.id,
                statuscomplete: 'T',
            }).then(response => {
                //console.log(response.params)
                /*this.$store.dispatch('setUserData', {
                    user: response.params,
                });*/
                this.$store.dispatch('retrieveUserData',{
                        usrID: user.id,
                });
                this.$global.creditcardsetup = true;
            },error => {
                
            });
        },
    },
    watch: {
        agreeTermBilling: {
            handler(newValue){
                if(newValue){
                    this.agreeTermStatBilling = false;
                }
            }
        }
    }
}
</script>

<style>

</style>